import React from 'react';
import { Link } from 'gatsby';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const ImpressumPage = () => (
  <Layout>
    <Container>
      <h1>Impressum</h1>
      <p>Das hier ist noch ein Platzhalter</p>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
);

export default ImpressumPage;
